<template>
    <font-awesome-icon icon="exclamation-triangle"
                       role="button"
                       v-b-tooltip.hover="title"
                       :style="{ color: color, fontSize: size, verticalAlign: 'sub' }" />
</template>

<script>
    export default {
        name: 'HasLackFromOtherControlIcon',
        props: {
            color: {
                required: false,
                type: String,
                default: 'red'
            },
            title: {
                required: false,
                type: String,
                default: 'Det finns en brist som ännu inte är åtgärdad'
            },
            size: {
                required: false,
                type: String
            }
        },
    };
</script>